import React from 'react';
import {graphql} from "gatsby";
import Layout from '../../components/theme/Layout'
import Header from '../../components/theme/Header'
import PetsPage from '../../components/pages/PetsPage';


const Pets = ({data}) => (
  <Layout
    meta={{
      title: 'Projet un abri pour chaque ami | ROYALE®'
    }}
    urls={{
      'en': `/pets`,
      'fr': `/fr/ami`
    }}
    locale='fr'
    ogimage="https://royaleapi.irvingconsumerproducts.com/globalassets/promotion/pets/HFEPP-fr-share.jpg"
  >
    <Header
      urls={{
        'en': `/pets`,
        'fr': `/fr/ami`
      }}
      locale='fr'
    />
    <PetsPage blogPosts={data.prismic} locale='fr' />
  </Layout>
)

export const query = graphql`
{
  prismic {
    blog_1: home_solution_article(uid: "tenir-les-oreilles-de-nos-petites-boules-de-poils-bien-propres", lang: "fr-ca") {
      title
      preview_image
      _meta {
        id
        uid
      }
    }
    blog_2: home_solution_article(uid: "un-truc-incomparable-quand-vos-animaux-perdent-leurs", lang: "fr-ca") {
      title
      preview_image
      _meta {
        id
        uid
      }
    }
    blog_3: home_solution_article(uid: "a-bas-les-pattes-sales", lang: "fr-ca") {
      title
      preview_image
      _meta {
        id
        uid
      }
    }
  }
}
`

export default Pets
